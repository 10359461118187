<template>
  <div>
    <div class="title-box">
      <div class="back-box" @click="_back">
        <i class="el-icon-back"></i>返回
      </div>
      <div class="line"></div>
      <div class="title-box-text"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    _back () {
      this.$router.back()
    }
  }
}
</script>

<style lang='scss' scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .back-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #303133;
    cursor: pointer;
    .el-icon-back {
      margin-right: 9px;
      font-size: 18px;
      color: #606266;
    }
  }
  .line {
    margin: 0 16px;
    width: 1px;
    height: 16px;
    background-color: #f3f3f3;
  }
  .title-box-text {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
  }
}
</style>
